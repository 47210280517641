<script lang="ts" setup>
const { t } = useT();
const { open, close, isOpen } = useFunrizeModals();
const { durationLeft, promoOffer, handleOpenCash } = useExitOffer(open, close, isOpen);

const coins = computed(() => promoOffer.value?.promoOfferPreset?.coins || promoOffer.value?.preset?.coins);
const entries = computed(() => promoOffer.value?.promoOfferPreset?.entries || promoOffer.value?.preset?.entries);
const badgeLabel = computed(() =>
	promoOffer.value?.promoOfferPreset?.badgeLabel ? parseJSON(promoOffer.value.promoOfferPreset.badgeLabel) : {}
);

const handleClick = () => {
	handleOpenCash("sidebar_exit_timer");
};
</script>
<template>
	<div class="event-item" @click="handleClick">
		<ACornerBadge v-if="badgeLabel?.percent && badgeLabel?.text" variant="accent" modifiers="right" class="top-right">
			<AText variant="topeka" :modifiers="['bold']">{{ badgeLabel.percent }}</AText>
			<AText variant="tallinn" :modifiers="['uppercase', 'normal']">{{ badgeLabel.text }}</AText>
		</ACornerBadge>
		<div class="prize-wrap">
			<MPrizeFund v-if="coins" variant="coins" icon="16/coins" :icon-size="16">
				<AText class="text-chittagong" variant="toledo" :modifiers="['bold']">
					{{ numberFormat(coins) }}
				</AText>
			</MPrizeFund>
			<MPrizeFund v-if="entries" variant="entries" icon="20/entries" :icon-size="22">
				<AText class="text-cixi" variant="toledo" :modifiers="['bold']">
					{{ numberFormat(entries) }}
				</AText>
				<AText variant="texas" class="text-cixi count-after" :modifiers="['bold', 'uppercase']">
					{{ t("for free") }}
				</AText>
			</MPrizeFund>
			<AText variant="ternopil" class="text-cannes time">
				{{ t("Avaliable once:") }} <b>{{ durationLeft }}</b>
			</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.event-item {
	background: var(--gyumri);
	width: 100%;
	min-height: 72px;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 8px;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
}

.prize-wrap {
	display: flex;
	flex-direction: column;
	gap: 2px;

	&:deep(.prize) {
		gap: 3px;
	}

	.entries {
		transform: translateX(-2px);
	}
}

.icon {
	font-size: 16px;

	&:deep(svg) {
		path {
			fill: var(--coro);
		}
	}
}

.count-after {
	display: inline-flex;
	max-width: 24px;
	line-height: 1;
}

.top-right {
	position: absolute;
	top: -3px;
	right: -3px;
	border-radius: 0;
}
</style>
